<template>
  <v-form ref="form" v-model="valid" @input="checkFormIsValid()">
    <v-row dense>
      <v-col lg="4" md="4" sm="6" cols="12">
        <v-text-field
          v-model="formCampanha.nome"
          counter="50"
          label="Nome"
          :rules="[rules.required, rules.nomeLength]"
          @input="checkFormEdited()"
        ></v-text-field>
      </v-col>
      <v-col lg="4" md="4" sm="6" cols="12">
        <vuetify-money
          v-model="formCampanha.custoEstimado"
          label="Custo Estimado"
          valueWhenIsEmpty="0"
          :options="optionsVuetifyMoney"
          @input="
            checkFormIsValid();
            checkFormEdited();
          "
        />
      </v-col>
      <v-col lg="4" md="4" sm="6" cols="12">
        <vuetify-money
          v-model="formCampanha.crescimentoEstimado"
          :rules="[rules.required, rules.maiorCinco]"
          label="Crescimento Estimado"
          valueWhenIsEmpty="0"
          :options="optionsVuetifyPercent"
          @input="
            checkFormIsValid();
            checkFormEdited();
          "
        />
      </v-col>
      <v-col lg="12" md="12" sm="12" cols="12">
        <v-textarea
          v-model="formCampanha.objetivo"
          label="Objetivo"
          counter="2000"
          :rules="[rules.required, rules.objetivoLength]"
          @input="checkFormEdited()"
        ></v-textarea>
      </v-col>
      <v-col lg="4" md="4" sm="6" cols="12">
        <BaseSelect
          :items="tiposEventosSelect.items"
          label="Tipos Eventos"
          item-text="tipo_evento"
          item-value="id_tipo_evento"
          :preSelected="tiposEventosSelect.selected"
          disabled
          @change="
            tipoFechamento.idTipoEvento = $event;
            checkFormIsValid();
            checkFormEdited();
          "
        />
      </v-col>
      <v-col lg="4" md="4" sm="6" cols="12">
        <BaseSelect
          :items="tiposDadosSelect.items"
          label="Tipos Dados"
          item-text="descricao"
          item-value="id_tipo_dado"
          :preSelected="tiposDadosSelect.selected"
          :rules="[rules.required]"
          :loading="tiposDadosSelect.loading"
          @change="
            tipoFechamento.idTipoDado = $event;
            verificaDataMesFechado($event);
            formatDate();
            checkFormIsValid();
            checkFormEdited();
          "
        />
      </v-col>
      <v-col lg="4" md="4" sm="6" cols="12">
        <BaseDatePicker
          v-if="tipoFechamento.idTipoDado !== 'X'"
          label="Periodo de Fechamento"
          @change="
            date = $event;
            formatDate();
            checkFormEdited();
          "
          :disabled="tipoFechamento.idTipoDado === ''"
          :preDate="date"
          :minDate="minDate"
          :maxnDate="maxnDate"
        />
        <BaseRangeDatePicker
          v-else
          label="Periodo de Fechamento"
          @change="
            dates = $event;
            formatDate();
            checkFormEdited();
          "
          :disabled="tipoFechamento.idTipoDado === ''"
          :preDates="dates"
        />
      </v-col>
    </v-row>
    <v-row class="ml-1" v-if="tipoFechamento.idTipoDado == 'C'">
      <TableFechamentoSemanais :fechamentoSemanal="arrayFechamentos"/>
    </v-row>
    <v-btn
      :disabled="!valid"
      color="primary"
      @click="saveCampanha()"
      class="mt-3"
    >
      Avançar
      <v-icon right>
        mdi-arrow-right
      </v-icon>
    </v-btn>
  </v-form>
</template>

<script>
import rules from "@/mixins/inputRules";
import comissaoService from "@/services/comissao";
import campanhaService from "@/services/campanha";
import campanhas from "@/services/http/campanhaService";
import BaseSelect from "@/components/shared/NewBaseSelect";
import BaseRangeDatePicker from "@/components/shared/BaseRangeDatePicker";
import BaseDatePicker from "@/components/shared/NewBaseDatePicker";
import {
  parse,
  format,
  subMonths,
  setDate,
  lastDayOfMonth,
  compareAsc,
  getMonth,
  getYear
} from "date-fns";
import { mapActions, mapGetters } from "vuex";
import TableFechamentoSemanais from './TableFechamentoSemanais.vue';
export default {
  name: "FormCadastroCampanha",

  components: {
    BaseSelect,
    BaseDatePicker,
    BaseRangeDatePicker,
    TableFechamentoSemanais
  },

  mixins: [rules],

  data() {
    return {
      arrayFechamentos: [],
      valid: false,
      checkEdited: false,
      date: new Date().toISOString().substr(0, 7),
      minDate: new Date().toISOString().substr(0, 7),
      maxnDate: new Date().toISOString().substr(0, 7),
      dates: [new Date().toISOString().substr(0, 10)],
      statusCamp: "",
      tiposDados: {
        R: "mês fechado",
        M: "mês referência",
        C: "semanal"
      },
      formCampanha: {
        nome: "",
        custoEstimado: 0,
        crescimentoEstimado: 0,
        objetivo: "",
        idTipoFechamento: 0
      },
      tipoFechamento: {
        idTipoDado: "",
        idTipoEvento: "M",
        mesRef: "",
        anoRef: "",
        dataInicio: "",
        dataFinal: "",
        ano: "",
        mes: ""
      },
      optionsVuetifyMoney: {
        locale: "pt-BR",
        prefix: "R$",
        length: 11,
        precision: 2
      },
      optionsVuetifyPercent: {
        locale: "pt-BR",
        prefix: "%",
        length: 6,
        precision: 2
      },
      tiposEventosSelect: {
        items: [
          { rn: "3", id_tipo_evento: "M", tipo_evento: "CAMPANHA", status: "S" }
        ],
        selected: {
          rn: "3",
          id_tipo_evento: "M",
          tipo_evento: "CAMPANHA",
          status: "S"
        }
      },
      tiposDadosSelect: {
        items: [],
        loading: false,
        selected: null
      },
      rules: {
        nomeLength: v =>
          (v && v.length <= 50) || "Campo deve ter no máximo 50 caracteres",
        objetivoLength: v =>
          (v && v.length <= 2000) || "Campo deve ter no máximo 2000 caracteres",
        maiorCinco: value =>
          parseFloat(value) >= 5 || "O valor deve ser igual ou maior que 5,00"
      }
    };
  },

  computed: {
    ...mapGetters({
      getUser: "getUser"
    })
  },

  methods: {
    ...mapActions({
      resetCampanhaState: "campanha/resetCampanhaState",
      actionCampanha: "campanha/actionCampanha"
    }),

    checkFormEdited() {
      this.checkEdited = true;
    },

    nextStepEmpresas(idCampanha = false) {
      if (idCampanha) {
        return this.$router.push(`cadastro/${idCampanha}/empresas`);
      }
      return this.$router.push(this.$route.params.idCampanha + "/empresas");
    },

    verificaDataMesFechado(idTipoDado) {
      if (this.getUser.nivel != "1" && this.getUser.nivel != "2") {
        if (idTipoDado in this.tiposDados) {
          if (new Date().getDate() > 7) {
            this.tipoFechamento.idTipoDado = "";
            const textoMes = this.tiposDados[idTipoDado];
            this.$notify({
              text: `Data limite de inclusão para o ${textoMes} ultrapassado. Prazo máximo até o 5ª dia útil`,
              type: "error"
            });
          }
          this.date = new Date().toISOString().substr(0, 7);
          this.minDate = this.date;
          this.maxnDate = this.date;
        }
      }
    },

    async saveCampanha() {
      // verifica se houve aterção no formulario
      if (!this.checkEdited) return await this.nextStepEmpresas();

      //verifica se é edição
      if (this.$route.params.idCampanha) return await this.editiCampanha();

      //cadastra nova campanha
      return await this.postCampanha();
    },

    checkFormIsValid() {
      let valid = true;
      if (!this.formCampanha.nome) {
        valid = false;
      } else if (parseFloat(this.formCampanha.custoEstimado) == 0) {
        valid = false;
      } else if (parseFloat(this.formCampanha.crescimentoEstimado) == 0) {
        valid = false;
      } else if (!this.formCampanha.objetivo) {
        valid = false;
      } else if (!this.tipoFechamento.idTipoDado) {
        valid = false;
      }
      this.$emit("formIsValid", valid);
    },

    async checkExistPeriodoFechamento() {
      const { data } = await comissaoService.getTiposFechamento({
        id_tipo_dado: this.tipoFechamento.idTipoDado,
        id_tipo_evento: this.tipoFechamento.idTipoEvento,
        data_inicio: this.tipoFechamento.dataInicio,
        data_final: this.tipoFechamento.dataFinal,
        mes_ref: this.tipoFechamento.mesRef,
        ano_ref: this.tipoFechamento.anoRef
      });
      return data;
    },

    async checkExistPeriodoFechamentoSemanal() {
      const { data } = await comissaoService.getTiposFechamento({
        id_tipo_dado: this.tipoFechamento.idTipoDado,
        id_tipo_evento: this.tipoFechamento.idTipoEvento,
        mes_ref: this.tipoFechamento.mesRef,
        ano_ref: this.tipoFechamento.anoRef
      });
      return data;
    },

    async idTipoFechamento() {
      
      const checkPeriodoFechamento = this.tipoFechamento.idTipoDado == "C" ? await this.checkExistPeriodoFechamentoSemanal() : await this.checkExistPeriodoFechamento();
      if (checkPeriodoFechamento.total) {
        this.arrayFechamentos = checkPeriodoFechamento.data.sort((a, b) => new Date(a.data_inicio) - new Date(b.data_inicio));
        return checkPeriodoFechamento.data[0].id_tipo_fechamento;
      }

      const periodoFechamento = await this.sendPeriodoFechamento();
      return periodoFechamento.data.id_tipo_fechamento;
    },

    // adiciona uma nova campanha
    async postCampanha() {
      try {
        const id_tipo_fechamento = await this.idTipoFechamento();

        const { data } = await campanhaService.postCampanha({
          nome_campanha: this.formCampanha.nome,
          valor_custo: this.formCampanha.custoEstimado,
          perc_crescimento: this.formCampanha.crescimentoEstimado,
          objetivo_campanha: this.formCampanha.objetivo,
          id_tipo_fechamento
        });

        const response = this.arrayFechamentos.map(item => {
          return campanhas().campanhaTipoFechamentoSemanal().store({
            id_campanha: data.id_campanha,
            id_tipo_fechamento: item.id_tipo_fechamento
          })
        });

        await Promise.all(response);

        this.$notify({
          text: "Campanha salva com sucesso!",
          type: "success"
        });

        await this.nextStepEmpresas(data.id_campanha);
      } catch (error) {
        this.$notify({
          text: "Erro ao criar a campanha",
          type: "error"
        });
      }
    },

    // edita uma campanha existente
    async editiCampanha() {
      try {
        const id_tipo_fechamento = await this.idTipoFechamento();

        await campanhaService.putCampanha(this.$route.params.idCampanha, {
          nome_campanha: this.formCampanha.nome,
          valor_custo: this.formCampanha.custoEstimado,
          perc_crescimento: this.formCampanha.crescimentoEstimado,
          objetivo_campanha: this.formCampanha.objetivo,
          id_tipo_fechamento
        });

        this.$notify({
          text: "Campanha editada",
          type: "success"
        });
        this.nextStepEmpresas();
      } catch (error) {
        this.$notify({
          text: "Erro ao editar a campanha",
          type: "error"
        });
      }
    },

    async sendPeriodoFechamento() {
      try {
        const response = await comissaoService.postPeriodoFechamento({
          id_tipo_dado: this.tipoFechamento.idTipoDado,
          id_tipo_evento: this.tipoFechamento.idTipoEvento,
          data_inicio: this.tipoFechamento.dataInicio,
          data_final: this.tipoFechamento.dataFinal,
          ativo: this.tipoFechamento.ativo,
          mes: this.tipoFechamento.mes,
          ano: this.tipoFechamento.ano,
          mes_ref: this.tipoFechamento.mesRef,
          ano_ref: this.tipoFechamento.anoRef
        });
        return response;
      } catch (error) {
        return error;
      }
    },

    async fetchTiposDados() {
      try {
        this.tiposDadosSelect.loading = true;
        const { data } = await comissaoService.getTiposDados({
          ativa_campanha: "S"
        });
        this.tiposDadosSelect.items = data.data;
      } catch (error) {
        this.$notify({ type: "error", text: "Erro Carregar Tipos de Dados" });
      } finally {
        this.tiposDadosSelect.loading = false;
      }
    },

    async fetchTiposEventos() {
      try {
        this.tiposEventosSelect.loading = true;
        const { data } = await comissaoService.getTiposEvento();
        this.tiposEventosSelect.items = data.data;
      } catch (error) {
        this.$notify({ type: "error", text: "Erro Carregar Tipos de Eventos" });
      } finally {
        this.tiposEventosSelect.loading = false;
      }
    },

    formatDate() {
      const tipoDadosFormatos = {
        M: () => this.formatDateMesRef(),
        R: () => this.formatDateMesFechado(),
        X: () => this.formatRangeDate(),
        C: () => this.formatDateMesFechado()
      };

      tipoDadosFormatos[this.tipoFechamento.idTipoDado]();
      // if (this.tipoFechamento.idTipoDado === "M") {
      //   this.formatDateMesRef();
      // } else if (this.tipoFechamento.idTipoDado === "R") {
      //   this.formatDateMesFechado();
      // } else if (this.tipoFechamento.idTipoDado === "X") {
      //   this.formatRangeDate();
      // }
    },

    formatDateMesRef() {
      const date = parse(this.date, "yyyy-MM", new Date());
      this.tipoFechamento.dataInicio = format(
        setDate(subMonths(date, 1), 26),
        "yyyy-MM-dd HH:mm:ss"
      );
      this.tipoFechamento.dataFinal = format(
        setDate(date, 25),
        "yyyy-MM-dd HH:mm:ss"
      );

      this.setMonthAndYear();
    },

    formatDateMesFechado() {
      this.tipoFechamento.mesRef = this.date.split("-")[1];
      this.tipoFechamento.anoRef = this.date.split("-")[0];
      this.idTipoFechamento();
      const date = parse(this.date, "yyyy-MM", new Date());
      this.tipoFechamento.dataInicio = format(date, "yyyy-MM-dd HH:mm:ss");
      this.tipoFechamento.dataFinal = format(
        lastDayOfMonth(date),
        "yyyy-MM-dd HH:mm:ss"
      );

      this.setMonthAndYear();
    },

    formatRangeDate() {
      const arrDates = this.dates.map(date =>
        parse(date, "yyyy-MM-dd", new Date())
      );

      arrDates.sort(compareAsc);
      this.tipoFechamento.dataInicio = format(
        arrDates[0],
        "yyyy-MM-dd HH:mm:ss"
      );
      this.tipoFechamento.dataFinal = format(
        arrDates[arrDates.length - 1],
        "yyyy-MM-dd HH:mm:ss"
      );

      this.setMonthAndYear();
    },

    setMonthAndYear() {
      const date = parse(
        this.tipoFechamento.dataFinal,
        "yyyy-MM-dd HH:mm:ss",
        new Date()
      );
      this.tipoFechamento.ano = String(getYear(date));
      this.tipoFechamento.anoRef = this.tipoFechamento.ano;
      this.tipoFechamento.mes = String(getMonth(date) + 1);
      this.tipoFechamento.mesRef = this.tipoFechamento.mes;
    },

    //inicia formulario caso seja edição
    async setCampanhaForEditing() {
      const { data } = await campanhaService.getById(
        this.$route.params.idCampanha
      );
      this.formCampanha.nome = data.nome_campanha;
      this.formCampanha.custoEstimado = data.valor_custo;
      this.formCampanha.crescimentoEstimado = data.perc_crescimento;
      this.formCampanha.objetivo = data.objetivo_campanha;
      this.formCampanha.idTipoFechamento = data.id_tipo_fechamento;
      this.statusCamp = data.status_camp;
      this.setTipoEventoForEditing(data.id_tipo_evento);
      this.setTipoDadoForEditing(data.id_tipo_dado);
      this.setDateForEditing(data);
    },

    setTipoEventoForEditing(idTipoEvento) {
      this.tiposEventosSelect.selected = this.tiposEventosSelect.items.find(
        tipoEvento => tipoEvento.id_tipo_evento == idTipoEvento
      );
      this.tipoFechamento.idTipoEvento = idTipoEvento;
    },

    setTipoDadoForEditing(idTipoDado) {
      this.tiposDadosSelect.selected = this.tiposDadosSelect.items.find(
        tipoDado => tipoDado.id_tipo_dado == idTipoDado
      );
      this.tipoFechamento.idTipoDado = idTipoDado;
    },

    setDateForEditing(campanha) {
      if (campanha.id_tipo_dado == "X") {
        this.dates[0] = campanha.data_inicio.substr(0, 10);
        this.dates[1] = campanha.data_final.substr(0, 10);
      } else {
        this.date = campanha.data_final.substr(0, 7);
      }
      this.formatDate();
    },

    tipoEventoDefault(items) {
      const tipoEvento = items.find(item => item.id_tipo_evento == "M");
      return tipoEvento;
    }
  },

  async mounted() {
    await this.fetchTiposDados();
    if (this.$route.params.idCampanha) {
      await this.setCampanhaForEditing();
    }
  },

  beforeDestroy() {
    this.resetCampanhaState();
  }
};
</script>
